
.list-base {
  --surface-color: var(--w-color-neutral-10);
  --on-surface-color: var(--w-plain-text-color);
  --accent-color: var(--w-color-info-30);
}

.list-base-item:hover {
  --surface-color: var(--w-color-info-30);
  --on-surface-color: var(--w-color-neutral-10);
  --accent-color: var(--w-color-neutral-10);
}

.list-base {
  margin: 0;
  padding: 0;
  list-style-type: none;

  background-color: var(--surface-color);
  color: var(--on-surface-color);
}

.list-base-item {
  padding-inline: var(--w-size-30);
  padding-block: var(--w-size-10);
  display: flex;
  gap: var(--w-size-20);
  align-items: center;

  background-color: var(--surface-color);
  color: var(--on-surface-color);
}

.list-base-item-check {
  --size: var(--input-lookup-font-size);

  appearance: none;
  position: relative;
  height: var(--size);
  width: var(--size);
  border: var(--w-border-size-10) solid var(--on-surface-color);

  cursor: pointer;
}

.list-base-item-check:checked {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.list-base-item-check:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 50%;
  height: 2px;
  background-color: var(--surface-color);
}

.list-base-item-text {
  margin: 0;
  padding: 0;
  flex-grow: 1;

  cursor: pointer;
}
