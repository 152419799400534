
.dropdown-surface {
  position: fixed;
  margin: 0;
  padding: 0;
  top: calc(var(--anchor-top) + var(--anchor-height) + 1px);
  left: var(--anchor-left);
  width: var(--anchor-width);

  max-height: calc(var(--input-lookup-font-size) * 2 * var(--base-size));

  border: var(--w-border-neutral);
  box-shadow: var(--w-shadow-neutral);
}
