
.input-lookup {
  --input-lookup-font-size: var(--w-font-size-30);
}

.input-lookup {
  position: relative;
  width: 100%;

  font-size: var(--input-lookup-font-size);
  font-weight: var(--w-font-weight-20);
}
